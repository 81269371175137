import * as React from "react";
import Layout from "../components/views/layout"

const IndexPage = () => {
  return (
    <Layout>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
