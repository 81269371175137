import * as React from "react";
import Me from "../../images/me.jpeg";
import * as Styles from "../../styles/sass/module/_fv.module.scss";

const Fv = () => {
  return (
    <section className={Styles.fv}>
      <div className={Styles.fv__icon}>
        <img src={Me} alt="アイコン" />
        <h1 className={Styles.fv__title}>
          Portfolio of Shogo Matsuda
        </h1>
      </div>
      <p className={Styles.fv__text}>
        Hi!My name is Shogo Matsuda. 
      </p>
      <p className={Styles.fv__text}>
        I mainly work on website creation and web application development.
      </p>
      <p className={Styles.fv__text}>
        Good at backend development.
      </p>
      <p className={Styles.fv__text}>
        Thank you.
      </p>
    </section>
  );
};

export default Fv;
