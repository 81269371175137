// WEB site
import Hearbvest from  "../../images/works/hearbvest.png"
import html from "../../images/lang/html.svg";
import css from "../../images/lang/css.svg";
import php from "../../images/lang/php.svg";
import laravel from "../../images/lang/laravel.svg";
import javascript from "../../images/lang/javascript.svg";
import jquery from "../../images/lang/jquery.svg";
import mysql from "../../images/lang/mysql.svg";
import hikidashi from "../../images/works/hikidashi.png";
import aiagri from "../../images/works/aiagri.png";
import docker from "../../images/lang/docker.svg";

export const WorksWEBSITE = [
  {
    id: 1,
    name: "Hearbvest",
    img: Hearbvest,
    title: "Hearbvest",
    text: "This is an EC site that sells local prefectural products.",
    year: "2021",
    company: "",
    detail: {
      link: "https://hearbvest.jp/",
      detailText:
        "This is an EC site that sells local prefectural products. It is a cutting-edge service that allows you to read a QR code and receive a catalog gift. A perfect gift for friends and family.",
      langs: [
        {
          name: "html",
          img: html,
        },
        {
          name: "css",
          img: css,
        },
        {
          name: "javascript",
          img: javascript,
        },
        {
          name: "JQuery",
          img: jquery,
        },
        {
          name: "php",
          img: php,
        },
        {
          name: "laravel",
          img: laravel,
        },
        {
          name: "MySQL",
          img: mysql,
        },
      ],
    },
  },
  {
    id: 2,
    name: "#ヒキダシ",
    img: hikidashi,
    title: "#ヒキダシ",
    text: "This is a website that solves the problems of local Ehime residents.",
    year: "2021",
    company: "",
    detail: {
      link: "https://www.hikidashi-ehime.jp/",
      langs: [
        {
          name: "html",
          img: html,
        },
        {
          name: "css",
          img: css,
        },
        {
          name: "javascript",
          img: javascript,
        },
        {
          name: "JQuery",
          img: jquery,
        },
        {
          name: "php",
          img: php,
        },
        {
          name: "laravel",
          img: laravel,
        },
        {
          name: "MySQL",
          img: mysql,
        },
      ],
      detailText:
        "This is a website that solves the problems of local Ehime residents. You can choose from a variety of categories, and local companies in Ehime Prefecture will respond. We also offer coupons and a service where you can receive gifts by answering company questionnaires.",
    },
  },
  {
    id: 3,
    name: "AIagri.",
    img: aiagri,
    title: "AIagri.",
    text: "This website is a job brokerage service centered on agriculture.",
    year: "2021",
    company: "",
    detail: {
      link: "https://aiagri.jp/",
      langs: [
        {
          name: "html",
          img: html,
        },
        {
          name: "css",
          img: css,
        },
        {
          name: "javascript",
          img: javascript,
        },
        {
          name: "JQuery",
          img: jquery,
        },
        {
          name: "php",
          img: php,
        },
        {
          name: "laravel",
          img: laravel,
        },
        {
          name: "MySQL",
          img: mysql,
        },
      ],
      detailText:
        "This website is a job brokerage service centered on agriculture. We use LINE's Messaging API to send applicants lottery results, reminder notifications, and post-work questionnaires.",
    },
  },
];